import * as React from "react";
import {useInView} from 'react-intersection-observer';
import {trackEvent} from "../lib/track";

function Map() {
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true
  });

  if (inView) {
    trackEvent("Map", "Show", "", true);
  }

  return (
    <div ref={ref} className="section-map" id="section-map">
      {
        inView &&
        <iframe src="//widgets.2gis.com/widget?type=firmsonmap&amp;options=%7B%22pos%22%3A%7B%22lat%22%3A43.33393416957673%2C%22lon%22%3A76.97530031204224%2C%22zoom%22%3A16%7D%2C%22opt%22%3A%7B%22city%22%3A%22almaty%22%7D%2C%22org%22%3A%2270000001076086880%22%7D" width="100%" height="100%" frameborder="0" title="Карта проезда"/>
      }
    </div>
  );
}

export default Map;