import * as React from "react";
import {Alert, FloatingLabel, Spinner} from "react-bootstrap";
import {IMaskInput} from "react-imask";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import price from "../lib/price";
import PropTypes from "prop-types";
import {getUTM} from "../lib/utm";
import {trackEvent} from "../lib/track";

function OnlineForm({title, subject, selectTransport = "", noSelectTransport, alert}) {
  const [validated, setValidated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notice, setNotice] = React.useState({show: false, title: "", text: ""});

  //
  // const handleChange = (setter) => (event) => {
  //   setter(event.currentTarget.value);
  // }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.phone.value.match(/_/g)) {
      form.phone.setCustomValidity("Обязательное поле");
    } else {
      form.phone.setCustomValidity("");
    }
    setValidated(true);

    if (form.checkValidity() === false) {
      return;
    }

    setLoading(true);
    setTimeout(() => {
      fetch("https://totaldpf.kz/action.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject,
          name: form.name.value,
          phone: form.phone.value,
          transport: form.transport && form.transport.value && price.hasOwnProperty(form.transport.value) ? Object.values(price[form.transport.value]).join(" - ") : "",
          ...getUTM()
        }),
      }).then(resp => {
        if (resp.ok) {
          form.reset();
          setValidated(false);
          setNotice({show: true, title: "Запрос успешно отправлен", text: ""});
          trackEvent("Form", "Submit", "Online");
        } else {
          if (resp.status >= 400 && resp.status < 500) {
            setNotice({show: true, title: "Ошибка", text: "Проверьте правильность заполнения формы"});
          }
          if (resp.status >= 500) {
            setNotice({show: true, title: "Ошибка", text: "Запрос не может быть обработан"});
          }
          trackEvent("Form", "Error", `Online: ${resp.status}`);
        }
      }).catch(e => {
        setNotice({show: true, title: "Ошибка", text: `${e.name} - ${e.message}`});
        trackEvent("Form", "Error", `Online: ${e.name} - ${e.message}`);
      }).finally(() => {
        setLoading(false);
      })
    }, 1000)
  };

  return (
    <div className="online-form">
      {
        loading &&
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      }

      {
        notice.show &&
        <div className="notice">
          <p className="title">{notice.title}</p>
          {
            notice.text &&
            <p className="text">{notice.text}</p>
          }
          <Button variant="link" onClick={() => setNotice({...notice, show: false})}>Закрыть</Button>
        </div>
      }

      {
        title &&
        <p className="header">
          {title}
        </p>
      }

      {
        alert &&
        <Alert variant="warning">{alert}</Alert>
      }


      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <FloatingLabel label="Как вас зовут" className="mb-3" controlId="formOnlineName">
          <Form.Control type="text" name="name" maxLength={64}/>
        </FloatingLabel>

        <FloatingLabel
          controlId="phone"
          label=" Номер телефона"
          className="mb-3 required"
        >
          <IMaskInput
            mask={"+{7} (000) 000-00-00"}
            lazy={false}
            // true|false|'typed'
            // --- inputRef={el => this.input = el}  // access to nested input
            // DO NOT USE onChange TO HANDLE CHANGES!
            // USE onAccept INSTEAD
            onAccept={
              // depending on prop above first argument is
              // `value` if `unmask=false`,
              // `unmaskedValue` if `unmask=true`,
              // `typedValue` if `unmask='typed'`
              (value, mask) => {
                if (value && !value.match(/_/g)) {
                  mask.el.input.setCustomValidity("");
                }
              }
            }
            placeholder='Введите номер телефона'
            className={"form-control"}
            aria-label={"Номер телефона"}
            aria-required={true}
            name="phone"
            type="tel"
            required
          />
          <Form.Control.Feedback type="invalid" tooltip>
            Обязательное поле
          </Form.Control.Feedback>
        </FloatingLabel>

        {
          !noSelectTransport &&
          <FloatingLabel controlId="carType" label="Транспортное средство" className="mb-3">
            <Form.Select name="transport" defaultValue={selectTransport} aria-label="Выбор транспортного средства"
            >
              <option value="" aria-label="не выбрано"/>
              {
                Object.entries(price).map(([key, val]) => {
                  return <option key={key} value={key}>{val.title} ({val.price.toLocaleString("ru")} KZT)</option>
                })
              }
            </Form.Select>
          </FloatingLabel>
        }

        <Button variant="primary" size="lg" type="submit" className="mb-3">
          Отправить
        </Button>
      </Form>
    </div>
  )
}

OnlineForm.propTypes = {
  title: PropTypes.node,
  subject: PropTypes.string.isRequired,
  noSelectTransport: PropTypes.bool,
  selectTransport: PropTypes.oneOf(Object.keys(price)),
  alert: PropTypes.node,
}

export default OnlineForm;
