import * as React from "react"
import withLayout from "../hoc/withLayout";
import Map from "../components/Map";
import RegenerationBgImg from "../images/regeneration-bg.jpg"
import RegenerationBgImgWebp from "../images/regeneration-bg.jpg.webp"
import Regeneration01Img from "../images/regeneration-01.jpg"
import Regeneration01ImgWebp from "../images/regeneration-01.jpg.webp"
import Regeneration02Img from "../images/regeneration-02.jpg"
import Regeneration02ImgWebp from "../images/regeneration-02.jpg.webp"
import Regeneration03Img from "../images/regeneration-03.jpg"
import Regeneration03ImgWebp from "../images/regeneration-03.jpg.webp"
import Regeneration04Img from "../images/regeneration-04.jpg"
import Regeneration04ImgWebp from "../images/regeneration-04.jpg.webp"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalCallback from "../components/ModalCallback";
import "./regeneration.scss"

// markup
const RegenerationPage = () => {
  return (
    <>
      <header className={'regeneration-header'}>
        <picture>
          <source type="image/webp" srcSet={RegenerationBgImgWebp}/>
          <source type="image/jpeg" srcSet={RegenerationBgImg}/>
          <img src={RegenerationBgImg} alt="регенерация сажевого фильтра"/>
        </picture>

        <div className={'header-wrapper'}>
          <Container>
            <h1>
              <span>Что делать, если</span>
              <span>не запускается <small>регенерация сажевого фильтра</small></span>
            </h1>
          </Container>
        </div>
      </header>

      <main>
        <div className={'regeneration-alert shadow-lg'}>
          <p>
            Не проходят прожиги? Не рискуй!
            Запишись на диагностику <br/>сажевого фильтра
          </p>

          <ModalCallback
            btnChild={'Записаться'}
            btnProps={{size: 'lg'}}
            header={'Записаться на диагностику сажевого фильтра'}
          />
        </div>


        <Container>
          <Row className={'mb-5'}>
            <Col xs={12} lg={6}>
              <p>Итак, различают 2 типа регенерации сажевого фильтра: активную и пассивную.</p>

              <h2>Активная регенерация</h2>

              <p>
                Она запускается автоматически, когда срабатывает датчик,
                отвечающий за проходимость газов и количество сажи.
              </p>

              <p>
                Происходит это из-за увеличения температуры.
                Она повышается либо из-за дополнительного впрыска топлива в выхлопную систему
                либо из-за подачи топлива в двигатель.
              </p>

              <p>
                Таким образом, нагар, копоть и сажа прогорают, позволяя сажевому фильтру работать дальше.
              </p>
            </Col>

            <Col xs={12} lg={6}>
              <picture>
                <source type="image/webp" srcSet={Regeneration01ImgWebp}/>
                <source type="image/jpeg" srcSet={Regeneration01Img}/>
                <img src={Regeneration01Img} className='w-100 img-fluid' alt="датчик проходимости газов и количества сажи" />
              </picture>
            </Col>
          </Row>

          <Row className={'mb-5 flex-lg-row-reverse'}>
            <Col xs={12} lg={6}>
              <h2>Пассивная регенерация</h2>
              <p>
                Происходит во время движения автомобиля под воздействием температуры выхлопных газов.
                Но здесь важно уточнить: температура нужна довольно высокая, а двигаться в таком случае следует довольно долго.
              </p>

              <p>
                Пассивная регенерация должна происходить 2-3 раза в интервале замены моторного масла.
                Для того, чтобы такая регенерация сработала, рекомендуется ездить по автомагистрали минимум 30-45 минут со скоростью не менее 80 км/ч.
              </p>

              <p>
                Что касается принудительной регенерации, она не всегда дает желаемый результат(даже на высоких скоростях).
              </p>

              <p>
                Если регенерация долгое время не запускается, то есть риск того, что автомобиль перейдет в аварийный режим.
                В таком случае, двигатель начинает работать с перебоями, электронная система управления переходит в аварийное состояние,
                а уровень масла в двигателе значительно увеличивается. Как итог — “стук” мотора.
              </p>
            </Col>

            <Col xs={12} lg={6}>
              <picture>
                <source type="image/webp" srcSet={Regeneration02ImgWebp}/>
                <source type="image/jpeg" srcSet={Regeneration02Img}/>
                <img src={Regeneration02Img} className='w-100 img-fluid' alt="движение автомобиля"/>
              </picture>
            </Col>
          </Row>

          <Row className={'mb-5'}>
            <Col xs={12} lg={6}>
              <h2>Прожиг НЕ запускается! Что делать?</h2>

              <p>
                Вероятнее всего, "сажевик" в таком случае забит.
                Также не будем забывать о том, что при <strong>продолжительных прожигах</strong> в сажевом фильтре
                образуется конечный продукт горения — зола, которую вывести можно только с помощью очистки.
              </p>

              <p>
                Будьте аккуратны с <strong>принудительными прожигами</strong>, ведь если с ними переборщить,
                то под давлением фильтр может потрескаться и даже разрушиться.
                А новый фильтр будет стоить "как крыло самолета".
              </p>

              <p>
                Если вы <strong>сталкиваетесь с проблемами при запуске регенерации</strong>,
                рекомендуется обратиться в компанию, которая специализируется на гидродинамической чистке сажевых фильтров.
                Для начала хотя бы на диагностику.
                <strong>Самый эффективный и безопасный способ чистки DPF фильтра</strong> осуществляется на оборудовании под названием TotalDPF.
              </p>
            </Col>

            <Col xs={12} lg={6}>
              <picture>
                <source type="image/webp" srcSet={Regeneration03ImgWebp}/>
                <source type="image/jpeg" srcSet={Regeneration03Img}/>
                <img src={Regeneration03Img} className='w-100 img-fluid' alt="оборудование TotalDPF для чистки DPF фильтров"/>
              </picture>
            </Col>
          </Row>

          <Row className={'mb-5 flex-lg-row-reverse'}>
            <Col xs={12} lg={6}>
              <h2>Чистка сажевого фильтра проходит в 4 этапа</h2>

              <ol>
                <li>Визуальный осмотр на наличие повреждений</li>
                <li>Проверка фильтра в специализированном стенде с целью оценки загрязнения</li>
                <li>4-х этапная мойка с использованием специального раствора</li>
                <li>Сушка с дальнейшей печатью полученных результатов</li>
              </ol>
            </Col>

            <Col xs={12} lg={6}>
              <picture>
                <source type="image/webp" srcSet={Regeneration04ImgWebp}/>
                <source type="image/jpeg" srcSet={Regeneration04Img}/>
                <img src={Regeneration04Img} className='w-100 img-fluid' alt="процесс чистки сажевого фильтра DPF"/>
              </picture>
            </Col>
          </Row>

          <Row className={'mb-5'}>
            <Col className={'d-flex justify-content-center'}>
              <ModalCallback
                btnChild={'Записаться на бесплатную диагностику сажевого фильтра'}
                header={'Записаться на диагностику сажевого фильтра'}
                btnProps={{size: "lg"}}
              />
            </Col>
          </Row>
        </Container>

        <Map/>
      </main>
    </>
  )
}


export default withLayout(
  RegenerationPage,
  "Что делать, если не запускается регенерация сажевого фильтра",
  "Не проходят прожиги? Не рискуй! Запишись на диагностику сажевого фильтра");
