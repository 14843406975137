const contacts = {
  phones: [
    {
      href: "tel:+77470702749",
      value: "+7-747-070 2749"
    },
  ],
  address: {
    city: "Алматы",
    street: "Майлина, 85/2",
    longitude: '',
    latitude: ''
  },
  workHours: [
    "Пн-Пт: 9:00 - 18:00",
    "Сб-Вс: по записи"
  ]
};

export default contacts;
