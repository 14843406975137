import * as React from "react"
import Modal from "react-bootstrap/Modal"
import Img from "../../images/popup-delivery.jpg";
import ImgWebp from "../../images/popup-delivery.jpg.webp";
import SmallImg from "../../images/popup-delivery_sm.jpg";
import SmallImgWebp from "../../images/popup-delivery_sm.jpg.webp";

export const Popup = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 5000)
    return () => {
      clearTimeout(timeout)
    } 
  }, [])

  const handleClose = () => setShow(false)

  return (
    <Modal show={show} onHide={handleClose} className="popup" size="xl" centered>
      <Modal.Header closeButton/>
      <Modal.Body>
        <picture>
          <source srcSet={SmallImg} media="(max-width: 1199px)" type="image/jpg"/>
          <source srcSet={SmallImgWebp} media="(max-width: 1199px)" type="image/webp"/>
          <source srcSet={Img} media="(min-width: 1200px)" type="image/jpg"/>
          <source srcSet={ImgWebp} media="(min-width: 1200px)" type="image/webp" />
          <img src={Img} alt="Чистка сажевого фильтра и катализатора - бесплатная доставка по Алматы" />
        </picture>
      </Modal.Body>
    </Modal>
  )
}