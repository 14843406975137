const price = {
  car: {
    title: "Легковой автомобиль",
    price: 80000
  },
  van: {
    title: "Коммерческий транспорт / микроавтобус / минивен",
    price: 100000
  },
  tir: {
    title: "Грузовой транспорт / TIR",
    price: 190000
  }
};

export default price;