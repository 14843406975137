import * as React from "react";
import {Link} from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LogoWhiteSvg from "../images/logo_white.svg";
import contacts from "../lib/contacts";
import "./footer.scss";
import PhoneLink from "./PhoneLink";
import MessengerWidget from "./MessengerWidget";



const Footer = () => {
  return (
    <footer id="section-footer" className="section-footer">
      <Container>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <Link to="/">
              <img src={LogoWhiteSvg} alt="Логотип TotalDPF белый" className="footer-logo img-fluid" />
            </Link>
          </Col>

          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <p className="address">{contacts.address.city}, {contacts.address.street}</p>
            <p className="phone"><PhoneLink phone={contacts.phones[0]}/></p>
          </Col>

          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <p>
              {contacts.workHours[0]}<br/>
              {contacts.workHours[1]}
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-muted">Copyright &copy; Total DPF. {new Date().getFullYear()} г. Все права защищены</p>
          </Col>
        </Row>
      </Container>
      <MessengerWidget/>
    </footer>
  )
}
export default Footer;